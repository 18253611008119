
//Navigation bar functionality
const liAbout = document.getElementById('liAbout');
const liExp = document.getElementById('liExp');
const liHobbies = document.getElementById('liHobbies');
const liFailures = document.getElementById('liFailures');
const liTalkToMe = document.getElementById('liTalkToMe');
const liSecret = document.getElementById('liSecret');

const sectionAbout = document.getElementById('sectionAbout');
const sectionExp = document.getElementById('sectionExp');
const sectionHobbies = document.getElementById('sectionHobbies');
const sectionFailures = document.getElementById('sectionFailures');
const sectionTalkToMe = document.getElementById('sectionTalkToMe');
const sectionSecret = document.getElementById('sectionSecret');


liAbout.addEventListener('click', function (event) {
    scrollTo(0, sectionAbout.offsetTop);
});

liExp.addEventListener('click', function (event) {
    scrollTo(0, sectionExp.offsetTop);
});

liHobbies.addEventListener('click', function (event) {
    scrollTo(0, sectionHobbies.offsetTop);
});

liFailures.addEventListener('click', function (event) {
    scrollTo(0, sectionFailures.offsetTop);
});

liTalkToMe.addEventListener('click', function (event) {
    scrollTo(0, sectionTalkToMe.offsetTop);
});

// liSecret.addEventListener('click', function (event) {
//     window.alert('I am insecure about dropping out of college');
// });


//Home button functionality
const home = document.getElementById('home');

home.addEventListener('click', function (event) {
    scrollTo(0, 0);
});


